import Vue from 'vue'
import { API } from '@/plugins/api'

Vue.prototype.$makeToast = function (
  mensajeColor,
  mensajeCabecera,
  mensajeCuerpo
) {
  this.$bvToast.toast(mensajeCuerpo, {
    title: mensajeCabecera,
    variant: mensajeColor,
    solid: true,
    toaster: 'b-toaster-top-right',
    appendToast: true,
  })
}

Vue.prototype.$validateState = function (ref) {
  if (
    this.veeFields[ref] &&
    (this.veeFields[ref].dirty || this.veeFields[ref].validated)
  ) {
    return !this.errors.has(ref)
  }
  return null
}

Vue.prototype.$fetchVuetable = async function (apiUrl, httpOptions) {
  const datos = await API.get(apiUrl, httpOptions)
  return datos.data
  // return datos.data si en el controlador de vue uso sendResponse o api() sino solo datos
}
